@media only screen and (max-width: 810px) {
    .observationsGrid {
        display:flex;
        flex-wrap:wrap;
        
        align-items:space-between;
        justify-content:space-evenly;
    }

    .lista-avistamentos{
        height: 65%;
        padding: 5px;
        overflow-y: auto;
        border-left: 1px solid grey;
        box-sizing: border-box;
    }

    .lista-avistamentos-validate{
        
        padding: 5px;

        border-left: 1px solid grey;
        box-sizing: border-box;
    }
}


@media only screen and (min-width: 810px) {
   
    .observationsGrid {
        display:flex;
        flex-wrap:wrap;
        
        
        height:100%;
        align-items:space-between;
        justify-content:space-evenly;
        grid-column-start: 2;
        grid-row-start: 2;
        scrollbar-width: thin;
        scrollbar-color: #0dafd0ff #f7f9f9;
    }

    .lista-avistamentos{
        grid-column-start: 2;
        padding: 5px;
        overflow-y: auto;
        border-left: 1px solid grey;
        box-sizing: border-box;
    }
    .lista-avistamentos-validate{
        grid-column-start: 2;
        padding: 5px;
        overflow-y: auto;
        border-left: 1px solid grey;
        box-sizing: border-box;
    }
}

.lista-avistamentos::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}

.lista-avistamentos::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.lista-avistamentos::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.lista-avistamentos::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.lista-avistamentos::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}

.lista-avistamentos-validate::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}

.lista-avistamentos-validate::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.lista-avistamentos-validate::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.lista-avistamentos-validate::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.lista-avistamentos-validate::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}