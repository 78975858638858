@media only screen and (max-width: 810px) {
    .validationModal {
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index:10000;
        left: 0;
        top: 0;
        

        background-color: #F7F9F9;

        
        
    }


}

@media only screen and (min-width: 810px) {
    .validationModal {
        position: absolute;
        height: 60vh;
        width: 60vw;
        z-index:10000;
        left: 50%;
        margin-top:-30vh;
        margin-left: -30vw;
        top: 50%;
        
    
        background-color: #F7F9F9;
    
        
    
        border-radius: 10px;
    }
    
    
    
}

.editAvatarModal {
    position: absolute;
    height: 350px;
    width: 450px;
    z-index:10000;
    left: 50%;
    margin-top:-175px;
    margin-left: -225px;
    top: 50%;
    

    background-color: #F7F9F9;


    border-radius: 10px;
}
.deletionModal {
    position: absolute;
    height: 200px;
    width: 200px;
    z-index:10000;
    left: 50%;
    margin-top:-100px;
    margin-left: -100px;
    top: 50%;
    

    background-color: #F7F9F9;


    border-radius: 10px;
}

.newPassModal {
    position: absolute;
    height: 350px;
    width: 300px;
    z-index:10000;
    left: 50%;
    margin-top:-175px;
    margin-left: -150px;
    top: 50%;
    

    background-color: #F7F9F9;


    border-radius: 10px;
}





.filterModal {
    position: absolute;
    height: 400px;
    width: 300px;
    max-width: 95vw;
    z-index:10000;
    left: 50%;
    margin-top:-200px;
    margin-left: -150px;
    top: 50%;
    

    background-color: #F7F9F9;


    border-radius: 10px;
}

.scrollbar-custom{
    scrollbar-width: thin;
    scrollbar-color: #0dafd0ff #f7f9f9;
}
    
.scrollbar-custom::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}
.scrollbar-custom::-webkit-scrollbar:horizontal{
    height:6px;
}
.scrollbar-custom::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.scrollbar-custom::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.scrollbar-custom::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.scrollbar-custom::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}




.expandable {
    height: 35px;
    border-top: 1px solid grey;
    cursor: pointer;
}

.expandable:last-of-type {
    height: 35px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    cursor: pointer;
}





