@media only screen and (max-width: 810px) {
    .sidebar{
        position: absolute;
        z-index: 1500;
        height: 100vh;
        width: 100vw;
        overflow-y: hidden;
        background-color: #F7F9F9;
        display: grid;
       
        grid-template-rows: 30% 55% 15%;
        
        
        
    }
    #logo{
        width: 75%;
        max-width:300px;
        margin-top:20px;
        margin-bottom: 15px;
        object-fit: contain;
    }
  }
@media only screen and (min-width: 810px) {
    .sidebar{
        position: absolute;
        z-index: 1600;
        height: 100%;
        width: 20vw;
        background-color: #F7F9F9;
        overflow-y: hidden;
        display: grid;
    
        -webkit-box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.75);
        scrollbar-width: thin;
        scrollbar-color: #0dafd0ff #f7f9f9;
        grid-template-rows: 30% 60% 10%;
        
        
    }
    #logo{
        width: 75%;
        max-width:300px;
        object-fit: contain;
       
        
        margin-top: 75px;

    }
}

.logo{
    display: flex;
    max-height: 300px;

    justify-content: center;
    
    grid-row: 1;
    


}

.menu-list {
    list-style:none;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-around;
    overflow-y:auto;
    margin-bottom: auto;
    grid-row: 2;
    height: 100%;
    align-content: flex-start;
    padding-top: 10%;
}



ul{
    margin:0;
    padding: 0;
    
}



* {
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    
}


.sidebar::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}
.sidebar::-webkit-scrollbar:horizontal{
    height:6px;
}
.sidebar::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.sidebar::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.sidebar::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.sidebar::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}

.menu-list::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}
.menu-list::-webkit-scrollbar:horizontal{
    height:6px;
}
.menu-list::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.menu-list::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.menu-list::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.menu-list::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}