@media only screen and (max-width: 810px) {
    .editing-modal{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 20% 5% 5%;
        column-gap: 5px;
        z-index: 15000;
    }
    .editing-imagem {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start:1;
        grid-row-end:5;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editing-form {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start:5;
        grid-row-end:9;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding:10px;
        box-sizing:border-box;
        margin-right: 5px;
    }

    .editing-buttons {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start:9;
        grid-row-end:10;
        display: flex;
        justify-content: space-evenly;
    }

    .editing-cancel {
        grid-column: 2;
        
        grid-row:10;
        text-align:center;
        
        cursor: pointer;
    }


}

@media only screen and (min-width: 810px) {
    .editing-modal{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
        column-gap: 5px;
        z-index: 15000;
    }

    .editing-imagem {
        grid-column: 1;
        grid-row-start:1;
        grid-row-end:8;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #editing-form {
        grid-column: 2;
        grid-row-start:1;
        grid-row-end:8;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding:10px;
        box-sizing:border-box;
        margin-right: 5px;
    }

    .editing-buttons {
        grid-column: 2;
        grid-row-start:9;
        grid-row-end:10;
        display: flex;
        justify-content: space-evenly;
    }

    .editing-cancel {
        grid-column: 2;
        grid-row:10;
        
        text-align:center;
        cursor: pointer;
    }
}