.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-button{
  display: inline-block;
    font-size: .882352941em;
    font-family:'Karla', verdana, sans-serif;
    padding: .4em .666666667em;
    line-height: 1.333333333;
    font-weight: normal;
    background-color: #0dafd0ff;
    border: 0.07143em solid #0dafd0ff;
    border-radius: .2em;
    border-bottom: 0.3em solid rgb(23, 142, 165);
    color: #fff;
    margin-left: .3em;
    margin-bottom: .3em;
    text-decoration: none;
    min-width: 82px;
}

.custom-button:disabled{
  display: inline-block;
    font-size: .882352941em;
    font-family:'Karla', verdana, sans-serif;
    padding: .4em .666666667em;
    line-height: 1.333333333;
    font-weight: normal;
    background-color: #D8D8D8;
    border: 0.07143em solid #D8D8D8;
    border-radius: .2em;
    border-bottom: 0.3em solid #D8D8D8;
    color: #898989;
    margin-left: .3em;
    margin-bottom: .3em;
    text-decoration: none;
    min-width: 82px;
}

.custom-button:active {
  display: inline-block;
    font-size: .882352941em;
    font-family:'Karla', verdana, sans-serif;
    padding: .4em .666666667em;
    line-height: 1.333333333;
    font-weight: normal;
    background-color: rgb(23, 142, 165);
    border: 0.07143em solid rgb(23, 142, 165);
    border-radius: .2em;
    border-bottom: 0.3em solid rgb(23, 142, 165);
    color: #fff;
    margin-left: .3em;
    margin-bottom: .3em;
    text-decoration: none;
    min-width: 82px;
}

.button-cancel {
  display: inline-block;
    font-size: .882352941em;
    font-family:'Karla', verdana, sans-serif;
    padding: .4em .666666667em;
    line-height: 1.333333333;
    font-weight: normal;
    background-color: #e60b0b;
    border: 0.07143em solid #e60b0b;
    border-radius: .2em;
    border-bottom: 0.3em solid #a90f0f;
    color: #fff;
    margin-left: .3em;
    margin-bottom: .3em;
    text-decoration: none;
    min-width: 82px;
}

.button-cancel:active {
  display: inline-block;
    font-size: .882352941em;
    font-family:'Karla', verdana, sans-serif;
    padding: .4em .666666667em;
    line-height: 1.333333333;
    font-weight: normal;
    background-color: #a90f0f;
    border: 0.07143em solid #a90f0f;
    border-radius: .2em;
    border-bottom: 0.3em solid #a90f0f;
    color: #fff;
    margin-left: .3em;
    margin-bottom: .3em;
    text-decoration: none;
    min-width: 82px;
}

@media only screen and (min-width: 810px) {
  
  button.mapboxgl-ctrl-attrib-button{
    display: none !important;
  }
}

.error-text{
  font-size: 13px;
  color: red;
}

.success-text{
  font-size: 13px;
  color: green;
}

.info-text{
  font-size: 13px;
}

input, textarea {
  -webkit-user-select:text;

}