.map {
    position: absolute;
    width: 100%;
    height: 100%;
  }


.mapbox-att {
  position: absolute;
  bottom:0px;
  left:0px;
}

/* .mapboxgl-control-container {
  position: absolute;
  bottom:0;
  right:0
}

.mapboxgl-canvas-container {
  height: 100%;
  width:100%
} */
/*  */