@media only screen and (min-width: 810px) {
    .page-body{
        
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: 100%;
    }
    .options-bar{
        overflow-y:auto;
        grid-column-start: 1;
        text-align: center
    }


    .user-image{
        width:90%;
        max-height:200px;
        
        object-fit:contain;
    }
    .options-bar-text{
        display:flex;
        flex-direction: column;
        justify-content:space-around;
        align-content:space-around;
        
       
    }
    /* .imagem-banner {
      
        max-height: 275px;
        width: 100%;
        object-fit: cover;
        background-repeat: no-repeat;
        overflow:hidden;
        background-size:cover;
        background-position: center;
    } */
}

@media only screen and (max-width: 810px) {
    .page-body{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
    }
    .user-image{
        width: 35%;
        max-height:200px;
        object-fit:contain;
    }
    .options-bar{
        overflow-y:auto;
        height:auto;
        text-align: center;
        height: 35%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-content: 'center';
    }

    .options-bar-text{
        display:flex;
        flex-direction: column;
        justify-content:space-around;
        align-content:space-around;
        width:75%;
        height:100%
    }


    .scrollbar-custom{
        scrollbar-width: thin;
        scrollbar-color: #0dafd0ff #f7f9f9;
    }
    
    .scrollbar-custom::-webkit-scrollbar{
        width: 6px;
        background-color:#f7f9f9;
    }
    .scrollbar-custom::-webkit-scrollbar:horizontal{
        height:6px;
    }
    .scrollbar-custom::-webkit-scrollbar-track{
    
        -webkit-box-shadow:0 0 6px #c8c8c8 inset;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb{
        background-color:#0dafd0ff;
        border:1px solid #0dafd0ff;
        
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:hover{
        background-color:#356f62;
        border:1px solid #356f62;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:active{
        background-color:#243e3e;
        border:1px solid #243e3e;
    }
}