@media only screen and (max-width: 810px) {
    .detalhesModal {
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index:10000;
        left: 0%;
        margin-top:0vh;
        margin-left: 0vw;
        top: 0%;
        

        background-color: #F7F9F9;

        display:grid;
        grid-template-rows: 10% 90%;

        
    }
    .modalHeader{
        display: flex;
        background-color: #0dafd0ff;
        color: #F7F9F9;
        justify-content: space-between;
        align-content: space-around;
        overflow:'hidden';
        font-family: Arial, Helvetica, sans-serif;
        
    }

}

@media only screen and (min-width: 810px) {
    .detalhesModal {
        position: absolute;
        height: 84vh;
        width: 80vw;
        z-index:1600;
        left: 50%;
        margin-top:-42vh;
        margin-left: -40vw;
        top: 50%;
        
    
        background-color: #F7F9F9;
    
        display:grid;
        grid-template-rows: 10% 90%;
    
        border-radius: 10px;
    }
    .modalHeader{
        display: flex;
        background-color: #0dafd0ff;
        color: #F7F9F9;
        justify-content: space-between;
        align-content: space-around;
        border-radius: 10px 10px 0 0;
        font-family: Arial, Helvetica, sans-serif;
        overflow:'hidden';
        
    }

    
}



.scrollbar-custom{
    scrollbar-width: thin;
    scrollbar-color: #0dafd0ff #f7f9f9;
}
    
.scrollbar-custom::-webkit-scrollbar{
    width: 6px;
    background-color:#f7f9f9;
}
.scrollbar-custom::-webkit-scrollbar:horizontal{
    height:6px;
}
.scrollbar-custom::-webkit-scrollbar-track{

    -webkit-box-shadow:0 0 6px #c8c8c8 inset;
}
.scrollbar-custom::-webkit-scrollbar-thumb{
    background-color:#0dafd0ff;
    border:1px solid #0dafd0ff;
    
}
.scrollbar-custom::-webkit-scrollbar-thumb:hover{
    background-color:#356f62;
    border:1px solid #356f62;
}
.scrollbar-custom::-webkit-scrollbar-thumb:active{
    background-color:#243e3e;
    border:1px solid #243e3e;
}




.expandable {
    height: 35px;
    border-top: 1px solid grey;
    cursor: pointer;
}

.expandable:last-of-type {
    height: 35px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    cursor: pointer;
}





