.form-element{
    margin-bottom: 20px;
}

@media only screen and (min-width: 810px) {
    .form-group{
        display:flex;
    }
    .form-group-element{
        width: 50%;
        padding: 20px;
    }
}
