.main-list-element {
    
    height: 100px;
    width: 100px;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    vertical-align: middle;
    border-radius: 10px;
    text-shadow:1px 1px 0px black;
    cursor: pointer;
    overflow: hidden;
    box-sizing: 'border-box';
    margin:10px
}

.main-list-element:active {
    
    height: 110px;
    width: 110px;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    vertical-align: middle;
    border-radius: 10px;
    text-shadow:1px 1px 0px black;
    cursor: pointer;
    overflow: hidden;
    box-sizing: 'border-box';
    margin:5px
}