@media only screen and (min-width: 810px) {
    .page-body-validate{
        display: flex;
        flex-direction: column;
        
    }
   



    /* .imagem-banner {
      
        max-height: 275px;
        width: 100%;
        object-fit: cover;
        background-repeat: no-repeat;
        overflow:hidden;
        background-size:cover;
        background-position: center;
    } */
}

@media only screen and (max-width: 810px) {
    .page-body-validate{
        display: flex;
        flex-direction: column;
        
        
    }
    .user-info-validate{
        overflow-y:auto;
        
        text-align: center
    }


    .scrollbar-custom{
        scrollbar-width: thin;
        scrollbar-color: #0dafd0ff #f7f9f9;
    }
    
    .scrollbar-custom::-webkit-scrollbar{
        width: 6px;
        background-color:#f7f9f9;
    }
    .scrollbar-custom::-webkit-scrollbar:horizontal{
        height:6px;
    }
    .scrollbar-custom::-webkit-scrollbar-track{
    
        -webkit-box-shadow:0 0 6px #c8c8c8 inset;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb{
        background-color:#0dafd0ff;
        border:1px solid #0dafd0ff;
        
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:hover{
        background-color:#356f62;
        border:1px solid #356f62;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:active{
        background-color:#243e3e;
        border:1px solid #243e3e;
    }
}