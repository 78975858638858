@media only screen and (min-width: 810px) {
    .login-page{
        
        display: grid;
        
        grid-template-columns: 50% 50%;
        grid-template-rows: 15% 85%;
        
    }
    .entrar{
    
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: 2;
        padding-left: 15%;
        padding-right: 15%;
        box-sizing: 'border-box';
        
    }
    .entrar-tab{
        grid-row: 1;
        grid-column: 1;
        cursor: pointer;
        height: 100%;
        margin:0;
        padding-left: 10px;
    }

    .registar-tab{
        grid-row: 1;
        grid-column: 2;
        cursor: pointer;
        height: 100%;
        margin:0;
        padding-left: 10px;
    }

    .esqueci {
        width: 75%;
        margin-left: 12.5%;
    }
    .registar{
        padding-left: 15%;
        padding-right: 15%;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row: 2;
       
        box-sizing: 'border-box';
    }
    /* .imagem-banner {
      
        max-height: 275px;
        width: 100%;
        object-fit: cover;
        background-repeat: no-repeat;
        overflow:hidden;
        background-size:cover;
        background-position: center;
    } */
}

@media only screen and (max-width: 810px) {
    .login-page{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        box-sizing: 'border-box';
        
    }
    .esqueci {
        width: 90%;
        margin-right: 5%;
    }
    .entrar{
        padding-left: 10px;
        padding-right: 10px;
       
    }

    .registar{
        padding-left: 10px;
        padding-right: 10px;
    }
    .entrar-tab{
        vertical-align: middle;
        cursor: pointer;
        height: 70px;
        margin:0;
        padding-left: 10px;
    }

    .registar-tab{
        vertical-align: middle;
        border-top: 1px solid black;
        cursor: pointer;
        height: 70px;
        margin:0;
        padding-left: 10px;
    }

    .scrollbar-custom{
        scrollbar-width: thin;
        scrollbar-color: #0dafd0ff #f7f9f9;
    }
    
    .scrollbar-custom::-webkit-scrollbar{
        width: 6px;
        background-color:#f7f9f9;
    }
    .scrollbar-custom::-webkit-scrollbar:horizontal{
        height:6px;
    }
    .scrollbar-custom::-webkit-scrollbar-track{
    
        -webkit-box-shadow:0 0 6px #c8c8c8 inset;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb{
        background-color:#0dafd0ff;
        border:1px solid #0dafd0ff;
        
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:hover{
        background-color:#356f62;
        border:1px solid #356f62;
    }
    .scrollbar-custom::-webkit-scrollbar-thumb:active{
        background-color:#243e3e;
        border:1px solid #243e3e;
    }
}